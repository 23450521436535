import { Routes, Route, Navigate } from "react-router-dom";
import AirtableComponent from "./components/Airtable/Airtable";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AirtableComponent />} />
      </Routes>
    </>
  );
}

export default App;
