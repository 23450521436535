import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cabochonImage from "../../assets/logo.PNG";

const AirtableComponent = () => {
  const [records, setRecords] = useState([]);
  const [offset, setOffset] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiKey = process.env.REACT_APP_AIRTABLE_ID;
  const baseId = process.env.REACT_APP_BASE_ID;
  const tableName = process.env.REACT_APP_TABLE_ID;
  const pageSize = 20;
  const fields = ["Name", "BJC Price", "BJC Nbr", "Notes", "Attachments"];
  const filterFormula = 'AND(ARRAYJOIN({BJC Sale}) = "BJC Sale")';
  const sort = [{ field: "Name", direction: "asc" }];

  const fetchRecords = async (offset = null) => {
    try {
      const url = new URL(`https://api.airtable.com/v0/${baseId}/${tableName}`);
      fields.forEach((field) => url.searchParams.append("fields[]", field));
      url.searchParams.append("filterByFormula", filterFormula);
      url.searchParams.append("pageSize", pageSize);
      sort.forEach(({ field, direction }, index) => {
        url.searchParams.append(`sort[${index}][field]`, field);
        url.searchParams.append(`sort[${index}][direction]`, direction);
      });
      if (offset) {
        url.searchParams.append("offset", offset);
      }

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching records: ${response.statusText}`);
      }

      const data = await response.json();
      setRecords((prev) => [...prev, ...data.records]);
      setOffset(data.offset || null);
    } catch (error) {
      setError(error);
      toast.error(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (offset) {
      fetchRecords(offset);
    }
  }, [offset]);

  const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  const openLightbox = (imgSrc) => {
    setLightbox({ open: true, imgSrc });
  };

  const closeLightbox = () => {
    setLightbox({ open: false, imgSrc: "" });
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto">
        <div className="w-full flex flex-col items-center bg-white p-4 shadow-md">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <img
              src={cabochonImage}
              alt="Cabochon"
              className="w-48 h-48 object-cover rounded md:mb-2 md:mr-6"
            />
            <div className="text-center md:text-left">
              <h1 className="font-bold text-purple">Cabochons for Sale</h1>
              <p className="text-gray-700">
                A unique collection of curated cabs from lapidary artists around
                the world
              </p>
            </div>
          </div>
          <div className="text-center">
            <p className=" text-gray-900">
              Please contact{" "}
              <a
                href="mailto:info@cabochonsales.com"
                className="text-purple underline"
              >
                info@cabochonsales.com
              </a>{" "}
              for purchasing and shipping information
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center mt-16 flex justify-center items-center">
            Loading... <i className="fa-solid fa-spinner fa-spin"></i>{" "}
          </div>
        ) : error ? (
          <div className="text-center mt-16 flex justify-center items-center">
            Error: {error.message}
          </div>
        ) : (
          <div className="p-4 space-y-4">
            <p className="rounded-md mx-auto w-fit text-white text-center font-bold bg-purple px-4 py-2.5">
              Click on any image to view larger size
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {records.map((record) => (
                <div
                  key={record.id}
                  className="bg-white p-4 border border-gray-400 rounded-md text-center"
                >
                  {record.fields.Attachments &&
                  record.fields.Attachments.length > 0 ? (
                    <img
                      src={
                        record.fields.Attachments[
                          record.fields.Attachments.length - 1
                        ].url
                      }
                      alt={record.fields.Name}
                      className="w-full h-[200px] mb-2 cursor-pointer"
                      onClick={() =>
                        openLightbox(
                          record.fields.Attachments[
                            record.fields.Attachments.length - 1
                          ].url
                        )
                      }
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                  <h2 className="text-xl font-semibold center">
                    {record.fields.Name}
                  </h2>
                  <p>${record.fields["BJC Price"]}</p>
                  <p>{record.fields["BJC Nbr"]}</p>
                  {record.fields.Notes && <p>{record.fields.Notes}</p>}
                </div>
              ))}
            </div>
          </div>
        )}

        {lightbox.open && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
            style={{ zIndex: "1000000000000000000000000000000000" }}
            onClick={closeLightbox}
          >
            <div className="rounded-md bg-white p-2">
              <img
                className="h-full max-h-[600px] max-w-full rounded-lg object-contain"
                src={lightbox.imgSrc}
                alt="Enlarged gallery photo"
                onClick={(e) => e.stopPropagation()} // Prevent click inside the lightbox from closing it
              />
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AirtableComponent;
